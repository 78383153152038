/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/**
 * Mv Address Locator demo - Just for fast viewing purposes
 * For demo purposes only
 * @type {MCEmbedWidgetInstance instance}
 */
(
    function () {
        const MCEmbedWidgetInstance = window.MCEmbedWidgetsSDK;

        MCEmbedWidgetInstance.init({
            containerId: "mc-embed-widget",
            apiBasePath: `${process.env.API_HOST}/api`,
            widgetOptions: {
                type: "spaces_overview",
                columnCount: 3,
                challengeSpaceOverviewPage: "/",
                challengeSpaceDetailPage: "/space-details.html",
            },

            //Default - Universiteit van Amsterdam
            organisationId: "6021e0c5-8798-4101-b597-895b9d91d913",
            apiKey: "3946f62c7459f5440441611444123dee6a4edc4d8304d229749b66ef0dad8552",

            //Hoogeschool amsterdam,
            // apiKey: "84f10620b580b394de7b6a48f9929d23a075879b81e98a8af89476940a2fe9d5",
            // organisationId: "240d6e23-83cc-4c17-bdb2-6494962aec43"
        });
    }
)();
//
